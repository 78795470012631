import React, { useEffect } from 'react'
import { Head } from '~/components'

const SeguradoraParceiras = () => {
  useEffect(() => {
    window.location.href = '/'
  }, [])

  return (
    <Head
      description="Liberty Seguros, Porto Seguros, HDI Seguros e muito mais!"
      title="Seguradora Parceiras"
    />
  )
}

export default SeguradoraParceiras
